.contact {
    max-width: 1100px;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
    margin-top: 50px;
  }

  .map-iframe{
    max-width: 1100px;
    width: 100%;
    border-radius: 6px;
    margin: auto;
    margin-top: 50px;
  }

  .map-iframe iframe{
    border-radius: 6px;
  }

  .contact .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contact .content .left-side {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }

  .contact .content .left-side::before {
    content: "";
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
  }

  .contact .content .left-side .details {
    margin: 14px;
    text-align: center;
  }

  .contact .content .left-side .details i {
    font-size: 30px;
    color: #0a534e;
    margin-bottom: 10px;
  }

  .contact .content .left-side .details .topic {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .contact .content .left-side .details .text-one,
  .contact .content .left-side .details .text-two {
    font-size: 14px;
    color: #afafb6;
  }
  
  .contact .content .right-side {
    width: 75%;
    margin-left: 75px;
  }

  .contact .content .right-side .topic-text {
    font-size: 23px;
    font-weight: 600;
    color: #0a534e;
    text-align: right;
  }

  .right-side .input-box {
    height: 55px;
    width: 100%;
    margin: 12px 0;
  }

  .right-side .input-box input,
  .right-side .input-box textarea {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #f0f1f8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }

  .right-side .message-box {
    min-height: 110px;
  }

  .right-side .input-box textarea {
    padding-top: 6px;
  }

  .right-side .button {
    display: inline-block;
    margin-top: 12px;
  }

  .right-side .button input[type="submit"] {
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 20px;
    border-radius: 6px;
    background: #0a534e;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .right-side .button input[type="button"]:hover {
    background: #0a534e;
  }

  .map-iframe{
    padding-top: 50px;
    padding-bottom: 50px;
  width:100%;
}

  
/* .numbers {
  font-size: 2em;
  font-weight: bold;
  margin-right: 0.5em;
} */
/* .text-two {
  unicode-bidi: bidi-override;
} */

  @media (max-width: 950px) {
    .contact {
      width: 90%;
      padding: 30px 40px 40px 35px;
      margin: auto;
    }
    .contact .content .right-side {
      width: 75%;
      margin-left: 55px;
    }
  }
  @media (max-width: 820px) {
    .contact {
      margin: 40px auto;
      height: 100%;
    }
    .contact .content {
      flex-direction: column-reverse;
    }
    .contact .content .left-side {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      flex-direction: row;
      margin-top: 40px;
      justify-content: center;
      /* flex-wrap: nowrap; */
    }

    .contact .content .left-side::before {
      display: none;
    }
    .contact .content .right-side {
      width: 100%;
      margin-left: 0;
    }
    .right-side .button {
      width: 100%;
    }

    .right-side .button input[type="submit"] {
      width: 100%;
      margin: auto;
    }

  }

  @media (max-width:525px){
    /* .contact .content .left-side .details {
     
      width: 30%;
  } */
  .contact .content .left-side {
    
    flex-direction: column;
  }

  }