.aboutUs{
    display: flex;
    justify-content: space-between;
    direction: rtl;
    margin-top: 15px;
    margin-bottom: 80px;
}

.aboutUs .right-aboutUs {
    flex: 1 1;
    padding: 20px;
    display: flex;
    justify-content: flex-start;

}
.aboutUs .right-aboutUs img{
    border-radius: 10px 10px 10px 140px;
    width: 85%;
    max-width: 500px;
    height: auto;
    margin: 0;
    display: block;
}

.aboutUs .left-aboutUs{
    line-height: 2;
    flex: 1 1;
    padding: 20px; 
}

.aboutUs .left-aboutUs h2{
    color: #2B2D42;
    font-size: 50px;
    font-weight: 500;
    padding-bottom: 10px;
}

.aboutUs .left-aboutUs p{
    color: #2B2D42;
    font-size: 22px;
}

.aboutUs .title__line{
    width: 20%;
    height: 4px;
    background-color: #ffc107;
    margin-bottom: 20px;
}

@media (max-width: 992px){
    .aboutUs .right-aboutUs img{
        width: 100%;
    }
    
    .aboutUs .left-aboutUs p {
        font-size: 16px;
        margin-top: 15px;
    }
    .aboutUs .left-aboutUs h2 {
        font-size: 32px;
    }
}

@media (max-width: 768px){
    .aboutUs{
        flex-direction: column;
    }
    .aboutUs .left-aboutUs h2 {
        font-size: 28px;
    }

}