.hero2_container {
    background: linear-gradient(rgba(7, 34, 7, 0.5), rgba(31, 71, 31, 0.5)), 
                url('../../assets/header-partners.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-size: cover;
    background-position: center;
    height: 70vh; 
    width: 100%; 
    position: relative;
}

.hero2_container .container {
    text-align: center;
}

.hero2_container h1 {
    font-size: 44px;
    margin: 0;
    line-height: 1.7;
}

.hero2_container .line{
    background: #F4A82E;
    height: 5px;
    width: 12%;
    margin:  auto;
}

@media(max-width: 540px) {
    .hero2_container h1 {
        font-size: 34px;
    }
}