.form-container {
    max-width: 800px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .form-container label{
    font-size: 14px;
    color: #434343;
  }

  .form-container input{
    padding: 12px;
    border-radius: 10px;
    background: #F5F5F5;
    border: none;
  }

  .form-container textarea{
    resize: none;
    background: #F5F5F5;
    border: none;
    padding: 12px;
    border-radius: 10px;
  }

  .form-container input::placeholder{
    color: #434343;
    font-size: 14px;
  }

  .form-container textarea::placeholder{
    color: #434343;
    font-size: 14px;
  }

  .form-container .submit-btn{
    background: #064E4D;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
    font-size: 14px;
    align-items: center;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-container button[type="submit"] {
    width: 100%;
  }
  
