.latest-jobs-section {
    margin-top: -200px;
    padding: 50px 0;
    text-align: center;
    /* position: absolute; */
}

.latest-jobs-section h2 {
    font-size: 40px;
}

.latest-jobs-section .title-line {
    width: 15%;
    height: 4px;
    background-color: #ffc107;
    margin: 15px auto 50px auto;
}

.jobCard {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
}

.jobCard .job-img {
    width: 130px;
    height: 130px;
    border-radius: 0px 10px 0 10px;
}

.jobCard .separator-line {
    width: 1px;
    height: 80%;
    background-color: #A2A2A2;
    position: absolute;
    right: 150px;
}

.jobCard .job-details {
    text-align: right;
    padding-right: 30px;
}

.jobCard .job-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 12px;
}

.jobCard p {
    margin-bottom: 16px;
    font-size: 20px;
}

.jobCard .read-details {
    background-color: #064E4D;
    padding: 8px 15px !important;
    color: white;
    border-radius: 20px;
    font-size: 18px;
    text-decoration: none;
    
}



.jobCard .read-details:hover{
    background: #F4A82E;
    transition: 0.3s;
}

/* Pagination Style */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;
}

.pagination-container .page-item .page-link {
    background: #EFEFEF;
    color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
}

.pagination-container .page-item.active .page-link {
    background-color: #F3F3F3;
}

@media (max-width:768px){
    .latest-jobs-section {
        margin-top: 50px;
      
    }
    .latest-jobs-section h2 {
        font-size: 34px;
    }
    .jobCard p {
        margin: 20px auto;
        font-size: 16px;
    }

    .jobCard .job-title {
        font-size: 18px;
    }
    .jobCard .read-details {
        padding: 8px 12px !important;
        font-size: 16px;
    }
}

@media (max-width:340px){
    .jobCard .read-details {
        padding: 8px 10px !important;
        font-size: 14px;
    }
}