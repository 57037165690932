/* global*/
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
}

a{
    text-decoration: none;
    text-transform: uppercase;
    }

ul{
    list-style: none
}

html{
    scroll-behavior: smooth;
    font-size: 10px;
}

.container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-left: auto;
    margin-right: auto;
}

.navbar-custom {
    background-color: #064E4D;
    top: 0;
    z-index: 999;
  }
  
  .navbar-nav {
    gap: 15px;
  }
  
  .nav-link {
    color: #FFFFFF !important;
    font-size: 20px !important;
  }
  
  .navbar-nav .nav-link:hover {
    color: #F4A82E !important;
  }
  
  .navbar-nav .nav-link.active {
    color: #F4A82E !important; 
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    list-style: none;
    gap: 4px;
  }
  
  .nav-logo {
    width: 178px;
  }


  .navbar-expand-lg .navbar-collapse {

    margin-left: 100px !important;
}


  @media(max-width: 992px){
    .nav-logo{
        display: none;
    }
    .custom-toggler .navbar-toggler-icon {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cpath stroke="rgba(255, 255, 255, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E');
        font-size: 30px;

    }
    .custom-toggler:focus,
.custom-toggler:active {
    border: none !important;
    box-shadow: none !important;
    outline: none !important; 
    margin: 10px 0;
}
  }
  