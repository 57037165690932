.why-us {
    display: flex;
    justify-content: space-between;
    z-index: 0;
    margin-top: 50px;
}


.right .h-us {
    font-size: 55px;
}

.right .line {
    background: #F4A82E;
    height: 4px;
    width: 15%;
}

.right .content .whyUs-p {
    line-height: 1.8;
    font-size: 22px;
    margin-top: 10px;
}

 .read__more {
    background: #064E4D;
    padding: 8px 20px;
    /* border: none; */
    color: #fff;
    border-radius: 30px;
    /* width: 200px; */
    font-weight: bold;
    margin-top: 20px;
    font-size: 22px;
    margin-bottom: 50px;
    text-decoration: none;
}

.content .read:hover {
    background: #F4A82E;
    color: #fff;
    transition: 0.3s;
}

button i {
    font-size: 10px;
}

.why-us .left img {
    width: 100%;
    display: block;
    margin: 0;
    height: auto;
}


@media (max-width: 768px){
    .why-us {
        flex-direction: column;
    }

    .right .h-us {
        font-size: 34px;
    }
    .right .content .whyUs-p{
        font-size: 18px;
        margin: 30px auto;
    }
    .why-us .left img{
        display: none;
    }
}