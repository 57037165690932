.jobs-section {
    padding: 50px 0;
    text-align: center;
    background: #F8F8F8;
    border-radius: 320px 0 320px 0;
    position: relative;
    bottom: 300px;
}

.jobs-section h2 {
    font-size: 40px;
    margin-bottom: 18px;
    color: #064E4D;
}

.jobs-section .title-line {
    width: 20%;
    height: 4px;
    background-color: #ffc107;
    margin: 0 auto 50px auto;

}

.row{
    display: flex;
    justify-content: center;
}

.card {
    border: none;
    margin-bottom: 30px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
    text-align: center;
}

.card:hover{
    border-radius: 65px 0 65px 0 !important;
    transition: 0.3s !important;
}

.card .card-img {
    background-color: #fff;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    border-radius: 50%;
    border: #064E4D 2px solid;
}

.card .card-img img{
    width: 100px;
}

.card .card-title {
    font-size: 22px;
    margin-bottom: 15px;
}

.read-more-btn{
    margin-top: 30px;
}

.jobs-section .read-more{
    font-size: 20px;
    background: #064E4D;
    color: #fff;
    padding: 15px 80px;
    border-radius: 35px;
    text-decoration: none;
    /* margin-top: 55px; */
}

.jobs-section .read-more:hover{
    background: #F4A82E;
    color: #fff;
}


@media (max-width: 768px){
    .jobs-section h2 {
        font-size: 34px;
    }

    .jobs-section {
        padding: 50px 0;
        text-align: center;
        background: #F8F8F8;
        border-radius: 0;
        position: static;
        bottom: 0;
    }
    .section-header{
        margin-top: 50px;

    }
    .card{
        width: 90%;
    margin: 15px auto
    }
    .card .card-img {
        width: 115px;
        height: 115px;
    }
    .card .card-img img {
        width: 70px;
    }
}