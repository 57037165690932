.hero_container {
    background: linear-gradient( rgba(7, 34, 7, 0.5), rgba(31, 71, 31, 0.5) ), url('../../assets/hero.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    height: 120vh; 
}

.hero_container .content_container {
    text-align: center;
    padding: 0; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 220px;
}

.hero_container h1 {
    font-size: 40px;
}

.hero_container h1 span {
    font-size: 65px;
    color: #ffc107;
}

.hero_container h3 {
    font-size: 40px;
    margin: 20px 0;
}

.hero_container p {
    font-size: 30px;
}

.hero-btns {
    display: flex;
    justify-content: center;
}

.hero_container .btn-custom {
    margin: 10px;
    padding: 10px 20px;
    background-color: #F4A82E;
    color: white;
    border-radius: 65px;
    /* display: flex; */
    align-items: center; 
    /* gap: 8px;  */
    font-size: 20px;
    text-decoration: none;
}

.hero_container .applier {
    color: #F4A82E;
    background: #fff;
}

.hero_container .owner{
    color: #fff;
}

.hero_container .btn-custom:hover {
    background-color: #064E4D;
    color: #fff;
    transition: 0.3s;
}


/* Responsive */
/* @media (max-width: 768px) {
    .hero h1 {
        font-size: 32px;
    }

    .hero h3 {
        font-size: 18px;
    }

    .hero p {
        font-size: 14px;
    }

    .hero {
        height: auto;
        padding: 50px 0;
    }

    .hero-btns {
        flex-direction: column;
    }
} */

@media (max-width: 768px) {
    .hero_container {
      
        height: auto; 
    }
    .hero_container .content_container {
      
        margin: 80px auto;
    }
    .hero_container h1 span {
        font-size: 42px;
    }

    .hero_container h1,
    .hero_container h3{
        font-size: 32px;
    }
    .hero_container p {
        font-size: 20px;
    }

    .hero_container .btn-custom {
      
        font-size: 18px;
    }

}

@media (max-width: 420px) {
    .hero-btns {
        flex-direction: column;
    }
}