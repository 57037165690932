.partners-container .hero {
    padding: 30px 0;
    height: auto;
}

.com-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
}

.com-container h1{
    color: #064e4d;
    margin-bottom: 15px;
}

.com-container .row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.com-container .row img {
    width: 100%;
    border-radius: 50px;
}

.partners-container .pagination {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
    /* margin: 20px 0; */
  }
  
 .partners-container button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    color: #fff;
    background: #064e4d;
    font-size: 16px;
    border-radius: 10px;
  }

  .partners-container .pagination span{
    color: #064e4d;
    font-size: 16px;
    margin: auto 0;
  }

/* media for contianer*/
 @media (min-width: 768px) {
    .com-container {
        width: 750px;
    }
}

 @media (min-width: 992px) {
    .com-container {
        width: 970px;
    }
}

 @media (min-width: 1200px) {
    .com-container {
        width: 1170px;
    }
}

@media(max-width: 768px) {
    .com-container .row {
        grid-template-columns: repeat(auto-fill, minmax(4, 1fr));
        grid-gap: 10px;
        text-align: center;
    }

    .com-container .row img {
        width: 50%;
    }
}

/* @media(max-width: 576px) {
    .com-container .row {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 20px;
        text-align: center;
    }
} */