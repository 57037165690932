.ownerServices{
    display: flex;
    justify-content: space-between;
    direction: rtl;
    margin-top: 15px;
    margin-bottom: 80px;
}

.ownerServices .right-ownerServices{
    line-height: 2;
    flex: 1;
    padding: 20px;
}

.right-ownerServices h2{
    color: #2B2D42;
    font-size: 50px;
    font-weight: 500;
    padding-bottom: 10px;
}

.right-ownerServices p{
    color: #2B2D42;
    font-size: 22px;
}

.ownerServices .title__line{
    width: 20%;
    height: 4px;
    background-color: #ffc107;
    margin-bottom: 20px;
}

.ownerServices .left-ownerServices{
    flex: 1;
    padding: 20px;
    display:flex;
    justify-content: flex-end;
}

.ownerServices .left-ownerServices img{
    width: 85%;
    max-width: 500px;
    height: auto;
    margin: 0;
   display:block;
    border-radius: 10px 10px 140px 10px;
}

@media (max-width: 992px){
    .ownerServices .left-ownerServices img{
        width: 100%;
    }
    .right-ownerServices h2 {
        font-size: 32px;
    }
    .right-ownerServices p {
        font-size: 16px;
        margin-top: 15px;
    }
}

@media (max-width: 768px){
    .ownerServices{
        flex-direction: column;
    }
    .right-ownerServices h2 {
        font-size: 28px;
    }

}