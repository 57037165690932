.footer {
    padding-bottom: 10px;
    padding-top: 20px;
    margin-top: 50px;
    min-height: 70vh;
    z-index: 0;
    overflow: hidden;
    position: relative;
    background-color: #064E4D;
}

.footer::after {
    content: '';
    position: absolute;
    top: -25%;
    left: 42%;
    transform: translateX(-50%);
    border-top: 35vh solid #fff;
    width: 120%;
    border-radius: 50%;
    z-index: -1;
}

.footer-row {
    display: flex;
    align-items: center;
    text-align: center;
        flex-wrap: wrap;
    /* padding: 15px; */
    /* margin: auto; */
    padding-top: 15rem;
    width: 100%;
}

.footer-txt {
    color: #ffffff;
}

.footer-col {
    margin: 5px 10px;
    text-align: center;
    width: calc((100% / 4) - 20px);
}

.footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    /* text-transform: capitalize; */
    margin: 20px 0;
    font-weight: 500;
}

.footer-col .social-links {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.footer-col .footer-ul ul {
    list-style: none;
    padding: 0;
}

.footer-col .footer-ul ul li {
    margin-bottom: 10px;
}

.footer-col ul li a {
    font-size: 16px;
    color: #bbbbbb;
    text-decoration: none;
    transition: all 0.3s ease;
} 

.footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 1px;
}

.footer-col .social-links {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #bbbbbb;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 117.687%;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.footer-col .social-links .footer-icon{
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 35px;
    height: 35px;
    padding: 8px;
}

.footer-col .social-links .footer-icon:hover {
    color: #ffffff;
}

.styled-paragraph {
    color: white;
    text-align: center;
}

.footer-link {
    text-decoration: none;
    font-size: 16px;
    color: #bbbbbb;
    transition: all 0.3s ease;
    cursor: pointer;
}

.footer-link:hover {
    color: #ffffff;
}

.footer-ul .phone{
    display: flex;
    justify-content: space-between;
}

.footer-logo {
    margin-bottom: 20px;
}

.footer-logo img {
    width: 350px;
}

@media (max-width: 992px) {
    .footer-row {
        flex-wrap: wrap;
    }

    .footer-col {
        width: 45%; 
        margin-bottom: 30px;
    }

    .footer-logo {
        width: 100%;
        order: -1;
    }
}

@media (max-width: 786px) {
    .footer-col {
        width: 100%; 
    }

    .footer-logo {
        width: 100%;
    }
}