.job-details{
    margin-top: 20px;
    line-height: 1.7;
}

.job-cont h2{
    font-size: 24px;
}

.job-cont .line{
    background: #F4A82E;
    height: 3px;
    width: 8%;
}

.job-cont p{
    font-size: 18px;
    white-space: pre-line;
}

.job-details .job-cont{
    margin-bottom: 15px;
}

.job-details .applyForJob{
    background: #064E4D;
    color: #fff;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 14px;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;

}

.job-details .applyForJob:hover{
    background: #F4A82E;
    transition: .3s;
}
