.social-btns {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 9999999;
  }
  
  .social-btns a {
    font-size: 24px;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-decoration: none;
  }

  .social-btns .facebook-btn{
    background-color: #3b5998;
  }

  .social-btns .whatsapp-btn{
    background-color: #43d854;
  }

  .social-btns .linkedin-btn{
    background-color: #0077b5;
  }