.available-jobs {
    padding: 50px 0;
    text-align: center;
    direction: rtl;
}

.job-card {
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px #F3F3F3;
    border-radius: 0 15px 0 15px;
    position: relative;
    gap: 10px;
}

.job-card img {
    width: 100px;
    height: 100px;
    border-radius: 0px 10px 0 10px;
}

.job-card .separator-line {
    width: 1px;
    height: 80%;
    background-color: #A2A2A2;
    position: absolute;
    right: 150px;
}

.job-card .job-details {
    text-align: right;
    /* padding-right: 30px; */
}

.job-card .job-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
}

.job-card p {
    margin-bottom: 10px;
    font-size: 18px;
}

.job-card .read-details {
    background-color: #064E4D;
    /* width: 202px; */
    padding: 5px 15px;
    color: white;
    border-radius: 20px;
    font-size: 14px;
    text-decoration: none;
}

.job-card .read-details:hover{
    background: #F4A82E;
}

/* Pagination Style */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;
}

.pagination-container .page-item .page-link {
    background: #EFEFEF;
    color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
}

.pagination-container .page-item.active .page-link {
    background-color: #F4A82E;
    border: none;
}

