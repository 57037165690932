.message{
    display: flex;
    justify-content: space-between;
    direction: rtl;
    margin-top: 15px;
    margin-bottom: 80px;
}

.message .right-message{
    line-height: 2;
    flex: 1;
    padding: 20px;
}

.our-message{
    margin: 40px 0;
}

.our-message h2,
.our-vission h2{
    color: #2B2D42;
    font-size: 50px;
    font-weight: 500;
    padding-bottom: 10px;
}

.our-vission p,
.our-message p{
    color: #2B2D42;
    font-size: 22px;
}

.message .title__line{
    width: 20%;
    height: 4px;
    background-color: #ffc107;
    margin-bottom: 20px;
}

.message .left-message{
    flex: 1;
    padding: 20px;
    display:flex;
    justify-content: flex-end;
}

.message .left-message img{
    width: 85%;
    max-width: 500px;
    height: 85vh;
    margin: 0;
   display:block;
    border-radius: 10px 10px 140px 10px;
}


@media (max-width: 992px){
    .message .left-message img{
        width: 100%;
    }
    .our-message h2, .our-vission h2 {
        font-size: 32px;
    }
    .our-vission p, .our-message p {
        font-size: 16px;
        margin-top: 15px;
    }
}

@media (max-width: 768px){
    .message{
        flex-direction: column;
    }

    .message .left-message img{
        height: 65vh;
    }

    .our-message h2, .our-vission h2 {
        font-size: 28px;
    }

}