.partners h1 {
  font-size: 50px;
  text-align: center;
  margin: 10px 0;
  color: #0a534e;
}

.partners .know {
  background: #064e4d;
  padding: 8px 20px;
  border: none;
  color: #fff;
  border-radius: 30px;
  margin-top: 20px;
  font-size: 18px;
}

.partners .know:hover {
  background: #f4a82e;
  color: #fff;
  transition: 0.3s;
}

.partners .line {
  background: #f4a82e;
  height: 4px;
  width: 10%;
  margin: auto;
}

.partners-slider {
  margin-top: 70px;  
}

.swiper-slide img {
  width: 50%;
  height: 100px; 
  object-fit: contain;  
}

.swiper-button-next,
.swiper-button-prev {
  color: #0a534e;
}

@media (max-width: 800px){
  .swiper-button-prev:after,
  .swiper-button-next:after{
    display: none;
  }
}

@media (max-width: 768px) {
  .partners h1 {
    font-size: 34px;
    margin-top: 80px;
  }

  .img-slider{
    width: 90px !important;
    margin: 0 15px !important;
  }
}

@media (max-width: 455px) {
  .img-slider{
    width: 60px !important; 
    margin: 0 40px !important;
  }
}