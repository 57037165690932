.applierServices{
    display: flex;
    justify-content: space-between;
    direction: rtl;
    margin-top: 15px;
    margin-bottom: 80px;
}

.applierServices .right-applierServices{
    flex: 1 1;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
}

.applierServices .right-applierServices img{
    border-radius: 10px 10px 10px 140px;
    width: 85%;
    max-width: 500px;
    height: auto;
    margin: 0;
    display: block;
}

.applierServices .left-applierServices{
    line-height: 2;
    flex: 1 1;
    padding: 20px; 
}

.our-applierServices h2{
    color: #2B2D42;
    font-size: 50px;
    font-weight: 500;
    padding-bottom: 10px;
}

.our-applierServices p{
    color: #2B2D42;
    font-size: 22px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.applierServices .title__line{
    width: 20%;
    height: 4px;
    background-color: #ffc107;
    margin-bottom: 20px;
}

.check-icon{
    color: #ffc107;
}

@media (max-width: 992px){
    .applierServices .right-applierServices img{
        width: 100%;
        height: 80vh;
    }
    .our-applierServices h2 {
        font-size: 32px;
    }
    .our-applierServices p {
        font-size: 16px;
        margin-top: 15px;
    }
}

@media (max-width: 768px){
    .applierServices{
        flex-direction: column;
    }
    .our-applierServices h2 {
        font-size: 28px;
    }
    .applierServices .right-applierServices img{
        height: 75vh;
    }

}