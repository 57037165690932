.statics {
    min-height: 100vh;
    background-size: cover;
    background: linear-gradient( rgba(7, 34, 7, 0.5), rgba(31, 71, 31, 0.5) ), url('../../assets/positive-hispanic-businessman-listening-colleague.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* z-index: 0; */
    overflow: hidden;
    /* position: relative; */
    /* margin-top: 50px; */

    position: relative;
    z-index: 99; /* لتكون الصورة فوق قسم Statics */
    margin-top: -50px; 
}

.statics {
   /* لتحريك القسم للأعلى قليلاً */
}


.statics .statics-num {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding-top: 14rem;
    text-align: center;
}

.statics::after {
    content: '';
    position: absolute;
    top: -38%;
    left: 50%;
    transform: translateX(-50%);
    border-top:50vh solid #fff;
    width: 120%;
    border-radius: 50%;
    z-index: 1;
}

.statics::before {
    content: '';
    position: absolute;
    bottom: -25%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 50vh solid #fff;
    width: 120%;
    border-radius: 50%;
    z-index: -1;
}

.statics-num .all {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 70px auto;
    width: 100%;
}

.statics-num .all .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
}

.statics-num .all .item .num{
    color: #FCC142;
    direction: ltr;
    font-size: 90px;
}

.statics-num .all .item .txt{
    color: #fff;
    font-size: 45px;
}

.btn-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

@media (max-width: 992px) {
    .statics-num .all .item .num {
        font-size: 50px;
    }
    .statics-num .all .item .txt {
        font-size: 30px;
    }
}

@media (max-width: 768px) {
    .statics::after, .statics::before {
        content: none;
    }
    .statics-num .all .item .num {
        font-size: 44px;
    }
    .statics-num .all .item .txt {
        font-size: 26px;
    }
}

@media (max-width: 500px) {
    .statics-num .all .item .num {
        font-size: 38px;
    }
    .statics-num .all .item .txt {
        font-size: 20px;
    }
}

@media (max-width: 390px) {
    .statics-num .all .item .num {
        font-size: 30px;
    }
    .statics-num .all .item .txt {
        font-size: 14px;
    }
}