.downlaod-file{
    text-align: center;
    margin-top: 25px;
}

.downlaod-file p{
    font-size: 18px;
    color: #064E4D;
    margin-top: 25px auto;
    font-weight: 500;
}

.download {
    cursor: pointer;
    /* height: 45px;
    width: 280px; */
    border-radius: 25px;
    font-size: 16px;
    background: none;
    border: none;
    background: #0a534e;
    cursor: pointer;
    padding: 10px 30px;
    color: #fff;
    /* margin-top: 35px; */
  }


  @media (max-width: 768px){
    .downlaod-file p {
        font-size: 14px;
        margin: 20px auto;
    }
  }