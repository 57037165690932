.form-container {
    max-width: 800px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-container h2 {
    color: #343a40;
    font-weight: bold;
  }

  .form-container label{
    font-size: 14px;
    color: #434343;
    margin-bottom: 10px;
  }

  .form-container .form-control{
    padding: 12px;
    border-radius: 10px;
    background: #F5F5F5;
    border: none;
  }

  .form-control:focus {
    outline: none; 
    border-color: #064E4D; 
    box-shadow: 0 0 5px rgba(6, 78, 77, 0.8); 
    background: #fff;
}




  .form-container .btn-file{
    background: #798887;
    color: #CECECE;
  }

  .form-container .submit-btn{
    background: #064E4D;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
    font-size: 14px;
    align-items: center;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-container button[type="submit"] {
    width: 100%;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
  }
  
  .radio-group label {
    margin-bottom: 10px;
  }
  
  

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 90%;
    position: relative;
    animation: slide-in 0.3s ease-out;
  }
  
  .popup-success {
    border-left: 5px solid #4CAF50; 
  }
  
  .popup-failure {
    border-left: 5px solid #F44336;
  }
  
  @keyframes slide-in {
    from {
      transform: translateY(-100px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover {
    color: red;
  }
  
  .popup-content p {
    font-size: 18px;
    margin: 0;
    color: #333;
  }
  
  /* Responsive styling */
  @media (max-width: 600px) {
    .popup-content {
      width: 95%;
    }
  }
  