.top{
    margin-top: 15px;
}

.top .container{
    display: flex;
    justify-content: space-between;
    /* gap: 10px; */
    direction: rtl;
}

.search-container{
    display: flex;
    width: 45%;
}

.search-icon{
    background-color: #F4A82E;
    color: #fff;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    border-radius: 5px;
}

.search-input{
    width: 90%;
    border: 3px solid #F0F2F3;
    border-right: none;
    font-size: 16px;
    padding: 0 10px;
    outline: none;
    cursor: pointer;
}

.search-input::placeholder{
    color: #C4C8C9;
    font-size: 16px;
}

.jobs-text{
    display: flex;
    gap: 5px;
    width: 38%;
    font-size: 22px;
}

@media (max-width: 580px) {
    
    .search-icon {
        width: 40px;
        height: 35px;
        font-size: 20px;
    }
    .jobs-text {
        font-size: 20px;
    }
}

@media (max-width: 420px) {
    .top .container {
        flex-direction: column;
    }
    .search-container {
        width: 100%;
        margin: 20px auto;
    }
    .jobs-text {
        text-align: center;
        margin: 0 auto;
        font-size: 14px;
    }
}